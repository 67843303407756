import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import axios from "axios";
import "./styles/style.css";
import "./styles/aboutme.css";
import Loading from "./Pages/Loading";

const Pages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { stranka } = useParams();

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/${stranka}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [stranka]);

  function renderButton() {
    if (stranka === "zakladni-vycvik") {
      return (
        <>
          <Button className="btn-primary" tag={Link} to="/prihlaska/form">
            Vyplnit přihlášku
          </Button>
        </>
      );
    }
  }

  useEffect(() => {
    FetchData();
  }, [FetchData]);
/*
  if (
    !"osvedceni".equals(stranka) ||
    !"terminy".equals(stranka) ||
    !"historie".equals(stranka) ||
    !"zakladni-vycvik".equals(stranka) ||
    !"pokracovaci-vycvik".equals(stranka) ||
    !"propagacni-seskoky".equals(stranka) ||
    !"pro-cleny".equals(stranka) ||
    !"kontakt".equals(stranka)
  ) {
    return (
      <>
        <NotFound />
      </>
    );
  }
  */
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (error) {
    return <Alert color="danger">Při získávání dat došlo k chybě.</Alert>;
  } else if (response) {
    return (
      <>
        <LayoutAll>
          <section className="introAll plain bg-white">
            <div className="inner-content container">
              <UnsafeComponent html={response.text} />
              {renderButton()}
            </div>
          </section>
        </LayoutAll>
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default Pages;
